<template>
  <div>
    <div v-if="isLoading">
      <Loading />
    </div>
    <div v-else>
      <!-- Authors Table -->
      <a-row :gutter="24" type="flex">
        <!-- Authors Table Column -->
        <a-col :span="24" class="mb-24">
          <!-- Authors Table Card -->
          <CardAuthorTable
            :data="serviceUnits"
            :columns="serviceUnitColumns"
            :pageTitle="pageTitle"
          ></CardAuthorTable>
          <!-- / Authors Table Card -->
        </a-col>
        <!-- / Authors Table Column -->
      </a-row>
      <!-- / Authors Table -->
    </div>
  </div>
</template>

<script>
import CardAuthorTable from "../components/Cards/CardAuthorTable";
import DataService from "../services/data.service";
import Loading from "../components/Loading/Loading.vue";
import { message } from "ant-design-vue";

export default {
  components: {
    CardAuthorTable,
    Loading,
  },
  data() {
    return {
      serviceUnits: [],
      pageTitle: "listServiceUnit",
      isLoading: true,
    };
  },
  computed: {
    serviceUnitColumns() {
      return [
        {
          title: "No",
          dataIndex: "index",
          key: "index",
        },
        {
          title: this.$t("label.image"),
          dataIndex: "images",
          scopedSlots: { customRender: "images" },
        },
        {
          title: this.$t("label.name"),
          dataIndex: "name",
          scopedSlots: { customRender: "name" },
        },
        {
          title: this.$t("label.tel"),
          dataIndex: "tel",
          scopedSlots: { customRender: "tel" },
        },
        {
          title: this.$t("label.address"),
          dataIndex: "address",
          scopedSlots: { customRender: "address" },
        },
        {
          title: this.$t("label.map"),
          dataIndex: "location",
          class: "text-success",
          scopedSlots: { customRender: "location" },
        },
        {
          title: "",
          scopedSlots: { customRender: "editBtn" },
          width: 50,
        },
      ];
    },
  },
  created() {
    this.getServiceUnits();
  },
  methods: {
    async getServiceUnits() {
      this.isLoading = true;
      await DataService.getServiceUnits()
        .then((res) => {
          this.serviceUnits.push(
            ...res.data.map((serviceUnit, index) => ({
              ...serviceUnit,
              key: serviceUnit.id,
              index: index + 1,
            }))
          );
        })
        .catch((err) => {
          message.error(err.message);
          this.isLoading = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss"></style>
