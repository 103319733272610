<template>
  <!-- Authors Table Card -->
  <a-card
    :bordered="true"
    class="header-solid h-full"
    :bodyStyle="{ padding: 0 }"
  >
    <template #title>
      <a-row type="flex" align="middle">
        <a-col :span="24" :md="12">
            <h5 class="font-semibold m-0">{{ pageTitle? $t(`label.${pageTitle}`): "" }}</h5>
        </a-col>
      </a-row>
    </template>
    <a-table :columns="columns" :data-source="data" :pagination="true">
      <template slot="index" slot-scope="index">
        {{ index }}
      </template>
      <template slot="images" slot-scope="images">
        <div class="table-avatar-info">
          <img :src="images" :width="100" />
        </div>
      </template>
      <template slot="name" slot-scope="name">
        <div class="author-info">
          <h6 class="m-0">{{ name }}</h6>
        </div>
      </template>
      <template slot="type" slot-scope="type">
        <div class="author-info">
          <h6 class="m-0">{{ type }}</h6>
        </div>
      </template>
      <template slot="tel" slot-scope="tel">
        <div class="author-info">
          <h6 class="m-0">{{ tel }}</h6>
        </div>
      </template>

      <template slot="address" slot-scope="address">
        {{ address }}
      </template>
      <template slot="location" slot-scope="text, record">
        <div class="author-info">
          <a v-bind:href="`https://www.google.com/maps/@${record.lat},${record.lng}`" target="_blank">{{
            $t("label.location")
          }}</a>
        </div>
      </template>
    </a-table>
  </a-card>
  <!-- / Authors Table Card -->
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    pageTitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
    };
  },
};
</script>
